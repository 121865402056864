.body {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    padding: 0 8px;
    margin: 0 auto;
}

.headerTitle {
    height: 44px;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #000;
    text-align: center;
    line-height: 44px;
    font-weight: bold;
}

.list {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.store {
    display: flex;
    align-items: center;
}

.storeIcon {
    margin-left: 10px;
    width: 24px;
    height: 24px;
}

.storeName {
    margin-left: 4px;
    font-family: PingFangSC-Medium;
    line-height: 22px;
    font-size: 16px;
    color: #303030;
}

.allStateDiv {
    margin-bottom: 40px;
}

.shopInfo {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 12px 12px 12px 12px;
    padding-bottom: 18px;
}

.shopImgName {
    display: flex;
    align-items: center;
    padding: 14px 12px 0 12px;
}

.shopImg {
    width: 30px;
    height: 30px;
    /* margin: 10px 0 0 10px; */
    border-radius: 50%;
}

.shopName {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #303030;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    margin-left: 4px;
    /* margin: 14px 0 0 4px; */
}

.shopState {
    flex: 1;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #fa6400;
    letter-spacing: 0;
    text-align: right;
    font-weight: 400;
    margin: 14px 17px 0 0;
}

.productInfo {
    display: flex;
    margin: 24px 0 0 10px;
    position: relative;
}

.productImg {
    background-size: contain;
    width: 100px;
    height: 100px;
    margin: 0 10px 0 10px;
    border-radius: 20px;
}

.productDesc {
    display: flex;
    flex-direction: column;
}

.productDescName {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #303030;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 600;
}

.productDescType {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #909090;
    letter-spacing: 0;
    font-weight: 400;
    margin: 2px 0 2px 6px;
}

.productDescPriceNum {
    display: flex;
    justify-content: space-between;
}

.productDescPrice {
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #000;
    letter-spacing: 0;
    text-align: right;
    font-weight: 600;
    word-break: break-all;
    margin-top: 29px;
}

.productDescNumInfo {
    display: flex;
    text-align: right;
    margin-left: 63px;
    position: absolute;
    right: 0;
}

.productDescNumImg {
    background: url('./assets/multi@3x.png') no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-top: 34px;
}

.productDescNum {
    margin-top: 32px;
    margin-right: 17px;
    word-break: break-all;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #909090;
    letter-spacing: 1px;
    text-align: right;
    font-weight: 600;
}

.itemPriceInfo {
    display: flex;
    justify-content: flex-end;
}

.itemPriceKey {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #303030;
    letter-spacing: 0;
    font-weight: 400;
    margin-top: 24px;
}

.itemPriceValue {
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #000;
    letter-spacing: 0;
    text-align: right;
    font-weight: 600;
    margin: 20px 24px 0 0;
}

.priceDownEmptyRow {
    height: 20px;
}

.actionBtnWrapper {
    background: #fff;
    padding-top: 28px;
}

.bodyBottom {
    height: 82px;
}

.orderReMarkInfo {
    display: flex;
    margin: 16.67px 0 0 10px;
}

.orderRemark {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #303030;
    letter-spacing: 0;
    font-weight: 400;
}

.inputRemark {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #bbbbbb;
    letter-spacing: 0;
    font-weight: 400;
    margin-left: 10.25px;
    border: 0;
    word-break: break-all;
    flex: 1;
}

.comment {
    background: #fff;
    display: flex;
    margin: 0 12px;
    padding: 20px 12px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #303030;
    line-height: 20px;
}

.commentLabel {
    width: 70px;
    margin-right: 10px;
}

.commentText {
    flex: 1;
}

.noSendDiv {
    margin-bottom: 25px;
}

.cancelBtnDiv,
.noPayBtnDiv {
    display: flex;
    justify-content: flex-end;
    height: 82px;
    background: #ffffff;
    box-shadow: 0 -2px 50px 0 rgba(0, 0, 0, 0.05);
}

.cancelBtnPos,
.noPayBtnPos {
    margin: 10px 17px 0 0;
}

.noReceiveBtnDiv {
    display: flex;
    justify-content: flex-end;
    height: 82px;
    background: #ffffff;
    box-shadow: 0 -2px 50px 0 rgba(0, 0, 0, 0.05);
    margin-top: 12px;
}

.noReceiveBtnPos {
    margin: 10px 17px 0 0;
}

.noPayOrderInfo {
    margin-bottom: 12px;
}

.finishDiv {
    margin-bottom: 66px;
}

.cancelBtnDiv {
    margin-top: 42px;
}

.actionSheetContainer {
    padding: 24px 58px 100px 58px;
}

.title {
}

.cardList {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.cardItem {
    width: 48px;
    height: 34px;
    padding: 4px;
    border: 1px solid #bfbfbf;
    margin-right: 20px;
}

.cardImg {
    display: block;
    width: 100%;
    height: 100%;
}

.payBtn {
    margin-top: 32px;
    height: 60px;
    background: #000;
    box-shadow: 0 10px 20px 0 rgba(48, 48, 48, 0.25);
    border-radius: 30px;
    font-family: PingFangSC-Regular;
    font-size: 20px;
    line-height: 60px;
    color: #fff;
}

.moneyUnit {
    font-size: 14px;
}

.orderItem {
    margin: 12px 12px 0 12px;
}
