/**
  26 * 62.5% === 10px === 1rem
 */

html {
    font-size: 62.5%;
}

@media (min-width: 320px) {
    html {
        font-size: 37.5%;
    }
}

@media (min-width: 375px) {
    html {
        font-size: 37.5%;
    }
}

/* @media (min-width: 640px) {
    html {
        font-size: 37.5%;
    }
} */

@media (min-width: 768px) {
    html {
        font-size: 31.25%;
    }
}

@media (min-width: 904px) {
    html {
        font-size: 40%;
    }
}

@media (min-width: 1000px) {
    html {
        font-size: 43.75%;
    }
}

@media (min-width: 1136px) {
    html {
        font-size: 50%;
    }
}

@media (min-width: 1280px) {
    html {
        font-size: 56.25%;
    }
}

@media (min-width: 1440px) {
    html {
        font-size: 62.5%;
    }
}

@media (min-width: 1920px) {
    html {
        font-size: 75%;
    }
}

@font-face {
    font-family: HelveticaNeue;
    src: url('./assets/fonts/HelveticaNeue.ttc');
}

@font-face {
    font-family: Roboto-Regular;
    src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: Roboto-Medium;
    src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
    font-family: Roboto-Bold;
    src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: HelveticaNeue-CondensedBold;
    src: url('./assets/fonts/Helvetica-Neue-Condensed-Bold.ttf');
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url('./assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: OpenSans-Bold;
    src: url('./assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
    font-family: OpenSans-SemiBold;
    src: url('./assets/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
    font-family: OpenSans-Regular;
    src: url('./assets/fonts/OpenSans-Regular.ttf');
}

/* viewport height === vh */
html body #root {
    min-height: 100vh;
    font-family: HelveticaNeue-CondensedBold;
}

.ant-dropdown.header-dropdown {
    padding-top: 10px;
    padding-left: 32px;
}
.ant-dropdown.header-dropdown ul {
    background: #2f2e2e;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
}
.ant-dropdown.header-dropdown .ant-dropdown-menu-item:hover {
    background: #2f2e2e !important;
    color: #fff !important;
}
.ant-dropdown.header-dropdown .ant-dropdown-menu-item.ant-select-item-option-active {
    background: #2f2e2e !important;
    color: #fff !important;
}
.ant-dropdown.header-dropdown ul li {
    width: 110px;
    line-height: 19px;
    margin-bottom: 5px;
    font-family: Roboto-Medium;
    font-size: 16px;
    color: #cccccc !important;
}

.adm-toast-icon {
    display: flex;
    justify-content: center;
}

:root:root {
    --adm-color-primary: #000;
}

.dropdown-select .ant-select-item-option-selected {
    color: #fff;
    background-color: #000;
}

.dropdown-select .ant-select-item-option-active {
    color: #fff;
    background-color: #404040;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
    border-bottom: 1px solid #000;
}
.tox.tox-tinymce {
    border: 1px solid #000;
}
.tox .tox-statusbar {
    border-top: 1px solid #000 !important;
}
.headerDrawer .ant-drawer-header {
    padding: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: #ddd !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: #ddd !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    color: #fff !important;
    background-color: #000 !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    color: rgba(255, 255, 255, 0.9) !important;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    color: rgba(255, 255, 255, 0.9) !important;
}
@media (max-width: 768px) {
    .ant-picker-panel-container .ant-picker-panels {
        flex-direction: column;
    }
}

.ant-select-item-option-active:not(.ant-select-item-option-selected) {
    color: #fff !important;
}
.ant-select-item-option-selected {
    background-color: #ddd !important;
}

.ant-modal-content{
    border-radius: 20px !important;
}
.ant-upload.ant-upload-select-picture-card{
    border: 1px solid rgba(0,0,0,0.15);
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px solid rgba(0,0,0,0.15);
}
/* .mantine-4ny9bc{
    opacity: 0;
} */

.ant-select-dropdown.address-modal-cc {
    width: 275px !important;
}

.ant-select-dropdown.address-modal-cc-mobile {
    width: calc(100vw - 82px) !important;
}

.ant-select-dropdown.personal-info {
    width: 580px !important;
}

.ant-select-dropdown.personal-info-mobile {
    width: calc(100vw - 42px) !important;
}

.address-modal-cc .ant-input-group-addon {
    margin-right: 0;
}

.ant-modal-close-x{
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}

.ant-modal-body{
    padding: 40px 24px 24px !important;
}

.adm-tabs-header {
    margin: 0 -8px !important;
}

.adm-toast-mask .adm-toast-main {
    word-break: break-word !important;
}

.ant-input:focus {
    box-shadow: none !important;
    outline: 'none' !important;
}

.add-wallet-modal .ant-modal-body {
    padding: 10px 24px 24px !important;
}
@media (max-width: 768px) {
    .add-wallet-modal .ant-modal-body {
        padding: 0 24px 24px !important;
    }
}
